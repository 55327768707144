<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                @click.prevent="goBack()"
                href="#"
                :disabled="item.disabled"
            >
                {{ item.text }}
            </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
            <v-overlay
          :absolute="absolute"
          :value="overlay"
        >
            <v-progress-circular
        indeterminate
        color="primary"
        ></v-progress-circular>
        </v-overlay>
        <v-card-title>Create Rate Card</v-card-title>
        <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('createfinal')" lazy-validation>
        <v-container class="fill-height"
        fluid>
        <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    label="Car Park"
                    v-model="carpark.selected"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    hide-details="auto"
                    background-color="white"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="rcname"
                        :rules="rcnamerule"
                        label="Rate Card Name"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <VueCtkDateTimePicker noHeader onlyTime input-size="sm" format="H.mm" v-model="cutoff" noValueToCustomElem color="#3D348B" button-color="#191716">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="cutoff"
                        :rules="cutoffrule"
                        label="Cutoff"
                        @keydown="isNumber($event)"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        maxlength="5"
                    ></v-text-field>
                    </VueCtkDateTimePicker>
                </v-col>
                <v-col cols="12" md="6">
                    <VueCtkDateTimePicker noHeader onlyTime input-size="sm" format="H.mm" v-model="grace" noValueToCustomElem color="#3D348B" button-color="#191716">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="grace"
                        :rules="gracerule"
                        @keydown="isNumber($event)"
                        label="Grace Period"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        maxlength="5"
                    ></v-text-field>
                    </VueCtkDateTimePicker>
                </v-col>
                <v-col cols="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :search="search"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{props.index + 1}}</td>
                            <td>{{props.item.day}}</td>
                            <td><v-btn x-small tile color="secondary" @click="viewMore(props.item.day, props.item.row, 'create')"> + Add Rate</v-btn></td>
                            <td>
                                <tr style="background: none;">
                                    <td style="border: none">
                                        <span v-for="(it, id) in props.item.rates" :key="id"><v-chip x-small @click="viewRate(props.item.day, props.item.row, it)" class="ma-1" color="blue" text-color="white">{{`Rate: ${id + 1}`}}</v-chip><v-btn color="red" icon x-small @click.prevent="viewDelete(id, props.index)"><v-icon class="custom-hover" color="red" x-small>fa fa-trash</v-icon></v-btn><br/></span>
                                    </td>
                                </tr>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                    <v-input
                        error
                        readonly
                        v-model="form[0].selected"
                        :rules="form[0].rule"
                        height="0"
                        class="hide-input-text"
                    >
                    </v-input>
                </v-col>
                <v-col cols="12" md="12">
                    <v-btn class="mt-4" tile small color="primary" type="submit" :loading="btn_loading"><v-icon left x-small>fa fa-save</v-icon> Save</v-btn>
                </v-col>
        </v-row>
        </v-container>
        </v-form>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_edit" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
        <alertbox :dialog="ma.alertmodal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
    </div>
</template>

<script>
import timer from '../../assets/js/sleeper.js';
import {ERR} from '../../assets/js/errhandle.js';
import create from '.././modals/AddRate';
import edit from '.././modals/EditRate';
import alertbox from '../modals/Alert';

export default {
    name: 'Create Rate Card',
    title: 'Create Rate Card',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },
                {
                text: 'Rate Card',
                disabled: false,
                href: '#',
                },
                {
                text: 'Create Rate Card',
                disabled: true,
                href: '#',
                },
            ],
            items: [
                {row: '1', day: 'Mon', add: '1', rates: []},
                {row: '2', day: 'Tue', add: '2', rates: []},
                {row: '3', day: 'Wed', add: '3', rates: []},
                {row: '4', day: 'Thu', add: '4', rates: []},
                {row: '5', day: 'Fri', add: '5', rates: []},
                {row: '6', day: 'Sat', add: '6', rates: []},
                {row: '7', day: 'Sun', add: '7', rates: []},
            ],
            rcname: '',
            rcnamerule: [],
            cutoffval: '',
            cutoffrule: [],
            graceval: '',
            gracerule: [],
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Day', value: 'day', width: '20%' },
                { text: 'Action', value: 'add', width: '10%' },
                { text: 'Rates', value: 'rates', width: '10%' },
            ],
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                alertmodal: false,

                modal_edit: false,
            },
            form: [ 
                {type: [], rule: [], selected: '' },
            ],
            role_selected: [],
            perm_list: [],
            select_all: false,
            btn_loading: false,
            access_record: [],
            overlay: false,
            absolute: true,
            loading: false
        }
    },

    components: {create, edit, alertbox},

    async created(){
        this.getCarPark();

        let self = this;
        window.onbeforeunload = await function() {
            let a = self.items.some(i => i.rates.length > 0);
            if (self.items.some(i => i.rates.length > 0))
            return true;
        }

        if (JSON.parse(JSON.stringify(this.$store.getters.getItems)).length > 0)
            this.items = JSON.parse(JSON.stringify(this.$store.getters.getItems));
        //this.$watch(() => this.items, this.checkTableRate, {deep: true});
    },

    async beforeRouteLeave (to, from , next) {
        await this.$store.dispatch("addtempitems", {items: this.items});
        let a = this.items.some(i => i.rates.length > 0);
        this.items = [];
        if (a){
            const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
            if (answer) {
                this.items = [];
                await this.$store.dispatch("addtempitems", {items: this.items});
                next()
            } else {
                next(false);
            }
        }

        else {
            next();
        }
    },

    computed: {
        cutoff: {
        // getter
        get: function() {
            return this.cutoffval;
        },
        // setter
        set: function(newValue) {
            if (newValue.length > 2) {
            newValue = newValue.replace(".", "");
            this.cutoffval =
                newValue.substr(0, newValue.length - 2) +
                "." +
                newValue.substr(newValue.length - 2);

            // add thousend separator formatting here
            } else {
            this.cutoffval = newValue;
            }
        }
        },

        grace: {
        // getter
        get: function() {
            return this.graceval;
        },
        // setter
        set: function(newValue) {
            if (newValue.length > 2) {
            newValue = newValue.replace(".", "");
            this.graceval =
                newValue.substr(0, newValue.length - 2) +
                "." +
                newValue.substr(newValue.length - 2);

            // add thousend separator formatting here
            } else {
            this.graceval = newValue;
            }
        }
        }
    },

    methods: {
        addRate(it){
            
        },

        async getCarPark(){
            let list = [];
            this.carpark.items = [];
            try{
                this.carpark.loader = true;
                this.carpark.disable = true;
                let response = await this.$store.dispatch("getAllCarpark2", {});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.carpark.items.push({name: "Select Car Park", CarParkID: ""});
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                        this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                    }
                }

                this.carpark.selected = this.carpark.items[0].CarParkID;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async addRateCard(){
            this.btn_loading = true;
            this.loading = true;
            try{
                let minutes = this.cutoff.split('.').reduce((acc,time) => (60 * acc) + +time);
                let cutoffinhours = minutes / 60;

                this.swal.scolor = 'green';
                let req = {carparkid: this.carpark.selected, name: this.rcname, cutoff: parseFloat(cutoffinhours), grace: parseFloat(this.grace),
                            mon: this.items[0].rates,
                            tue: this.items[1].rates,
                            wed: this.items[2].rates,
                            thu: this.items[3].rates,
                            fri: this.items[4].rates,
                            sat: this.items[5].rates,
                            sun: this.items[6].rates
                };
                let response = await this.$store.dispatch("createRateCard", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Rate Card Created!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This Rate Card is already created`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.loading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.loading = false;
                this.btn_loading = false;
            }
        },

        checkTableRate(v){
            let a = v.every(i => i.rates.length > 0);
            return a;
            //if (a == true){
                //this.form[0].rule = [];
            //}
        },

        viewMore(row, row2, flag){
            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
                this.ma.customText = {text1: row, item: row2};
            }
            
            this.ma.modal_action_type = flag;
        },

        viewRate(row, row2, itms){
            this.ma.modal_edit = true;
            this.ma.allInfo = this.items;
            this.ma.customText = {text1: row, item: row2, items: itms};
            
            this.ma.modal_action_type = 'edit';
        },

        onClosed(value){
            console.log(value);
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_edit = value;
                this.ma.alertmodal = value;
            }
        },

        onConfirm(value){
            console.log(value);
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                let [starthour, endhour, rate, maxrate, _continue, ratetypeFinal, subtime] = value;
                let a = JSON.parse(JSON.stringify(subtime.subtime));

                console.log('test', ratetypeFinal);

                for (let i=0; i<a.length; i++)
                delete a[i].rule;

                if (ratetypeFinal.selected == false){
                    this.items[this.ma.customText.item - 1].rates.push({
                    start_hour: starthour.starthour, end_hour: endhour.endhour, rate: rate.rate, max: maxrate.maxrate, continue: _continue.continue,
                    rate_type: ratetypeFinal.ratetypeFinal, subtime: a});
                }

                if (ratetypeFinal.selected == true){
                    this.items[this.ma.customText.item - 1].rates.push({
                    start_hour: starthour.starthour, end_hour: endhour.endhour, rate: rate.rate, max: maxrate.maxrate, continue: _continue.continue,
                    rate_type: ratetypeFinal.ratetypeFinal});
                }

                //let aa = this.items.map(row => this.pick(row, ['rates']));
                //console.log(aa);
            }

            if (value.flag === 'edit'){
                this.items[this.ma.customText.item - 1].rates.pop();
                console.log(value);
                this.ma.confirmCreate = true;
                this.ma.modal_edit = false;
                let [starthour, endhour, rate, maxrate, _continue, ratetypeFinal, subtime] = value;
                let a = JSON.parse(JSON.stringify(subtime.subtime));

                console.log('test', ratetypeFinal);

                for (let i=0; i<a.length; i++)
                delete a[i].rule;

                if (ratetypeFinal.selected == false){
                    this.items[this.ma.customText.item - 1].rates.push({
                    start_hour: starthour.starthour, end_hour: endhour.endhour, rate: rate.rate, max: maxrate.maxrate, continue: _continue.continue,
                    rate_type: ratetypeFinal.ratetypeFinal, subtime: a});
                }

                if (ratetypeFinal.selected == true){
                    this.items[this.ma.customText.item - 1].rates.push({
                    start_hour: starthour.starthour, end_hour: endhour.endhour, rate: rate.rate, max: maxrate.maxrate, continue: _continue.continue,
                    rate_type: ratetypeFinal.ratetypeFinal});
                }

                //let aa = this.items.map(row => this.pick(row, ['rates']));
                //console.log(aa);
            }

            if (value == true && this.ma.modal_action_type == "createfinal"){
                console.log('done');
                this.ma.alertmodal = false;
                this.addRateCard();
            }
        },

        pick(obj, keys) {
            return keys.map(k => k in obj ? {[k]: obj[k]} : {})
                    .reduce((res, o) => Object.assign(res, o), {});
        },

        viewDelete(i, mainidx){
            console.log(this.items[mainidx]);
            this.items[mainidx].rates.splice(i, 1);
        },

        async beforeCreateForm(flag){
            let check = await this.checkTableRate(this.items);
            console.log(check);

            this.form[0].rule = [
                v => check || 'Please ensure there are rates entered for all days of the week'
            ];

            this.rcnamerule = [
                v => !!v || 'Rate Card Name is required',
            ];

            this.cutoffrule = [
                v => !!v || 'Cut Off Time is required',
            ];

            this.gracerule = [
                v => !!v || 'Grace Period is required',
            ];

            this.carpark.rule = [
                v => !!v || 'Car Park is required',
            ];

            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                self.ma.alertmodal = true;
                //self.ma.modalInfo = {id: 1, name: 2};
                self.ma.modal_action_type = flag;
                    //self.addRateCard();
                }
            });
        },

        goBack(){
            this.$router.push({name: 'Car Park Rate'});
        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode != 9)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    }
}
</script>